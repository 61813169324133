<template>
  <div class="dialogueModel" :class="dialogueData.type">
    <div class="stemWrap">
      <img class="personIcon" :src="require(`@/assets/images/${dialogueData.type}.png`)">
      <div class="sentWrap" @click="playAudioHandle">
        <div class="sent">
          <div class="audio" ref="dialogueAudio"></div>
          <template v-for="(word, i) in words">
            <el-popover
              popper-class="wordTransPopper"
              placement="bottom"
              trigger="manual"
              v-model="wordTransVisible[i]"
              :key="i">
              <div class="wordTrans">{{word.tr}}</div>
              <div v-if="word.ty === '1'" class="punc" :class="{highlight: word.highlight}" slot="reference">{{word.wd}}</div>
              <div v-else class="word" :class="{active: i === activeIndex, highlight: word.highlight}" @click.stop="showWordTransHandle(word, i, true)" slot="reference">{{word.wd}}</div>
            </el-popover>
          </template>
        </div>
        <div class="trans">{{dialogueData['tr']}}</div>
      </div>
    </div>

    <div class="explain">{{dialogueData['ex']}}</div>
  </div>
</template>

<script>
  import dialogueMixins from '@/mixins/coursesDialogueMixins'

  export default {
    mixins: [ dialogueMixins ],
    data() {
      return {
        correct: true
      }
    },
    mounted() {
      this.playAudioHandle()
    }
  }
</script>