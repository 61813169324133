<template>
  <div class="dialogueFinish">
    <div class="imgWrap">
      <img src="@/assets/images/courses/dialogue/finish.png" alt="finish">
      <div class="finishText">{{$t('course.excellent')}}</div>
    </div>
    <div class="starWrap">
      <img src="@/assets/images/courses/dialogue/star.png" alt="star">
      <img src="@/assets/images/courses/dialogue/star.png" alt="star">
      <img src="@/assets/images/courses/dialogue/star.png" alt="star">
      <img src="@/assets/images/courses/dialogue/star.png" alt="star">
      <img src="@/assets/images/courses/dialogue/star.png" alt="star">
    </div>
    <div class="xp">{{$t('course._plus_s_XP').replace('%s', xp)}}</div>
    <div class="okBtn" @click="okClickHandle">{{$t('course.FINISH')}}</div>
  </div>
</template>

<script>
  import { updateHistoryData, updateDialogueProgressData } from '@/utils/dataUpdateUtil'

  export default {
    data() {
      return {
        xp: 5
      }
    },
    mounted() {
      this.updateData()
    },
    props: ['duration', 'coursesDialogueInfo'],
    methods: {
      okClickHandle() {
        this.$router.push('/courses')
      },
      updateData() {
        if (this.duration) {
          updateHistoryData(this.duration, this.xp)
        }
        if (this.coursesDialogueInfo) {
          updateDialogueProgressData(this.coursesDialogueInfo)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dialogueFinish {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imgWrap {
      position: relative;
      width: 238px;
      height: 260px;
      img {
        width: 100%;
      }
      .finishText {
        width: 100%;
        position: absolute;
        bottom: 76px;
        font-weight: 900;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
      }
    }
    .starWrap {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: 0 5px;
      }
      img:nth-child(1), img:nth-child(5) {
        width: 28px;
        margin-top: 40px;
      }
      img:nth-child(2), img:nth-child(4) {
        width: 32px;
        margin-top: 20px;
      }
      img:nth-child(3) {
        width: 48px;
      }
    }
    .xp {
      margin-top: 24px;
      font-weight: 900;
      font-size: 20px;
      color: #004E94;
    }
    .okBtn {
      margin-top: 100px;
      width: 300px;
      height: 46px;
      line-height: 46px;
      border-radius: 46px;
      background: #004E94;
      font-weight: 700;
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #0A4172;
      }
    }
  }
</style>