<template>
  <div class="coursesDialogue" @click="clearPopoverAll">
    <template v-if="finish">
      <finish :coursesDialogueInfo="coursesDialogueInfo" :duration="duration" class="animate__animated animate__fadeIn"></finish>
    </template>

    <template v-else>
      <div class="lessonHeader">
        <div class="close" @click="closeHandle">
          <img src="@/assets/images/clips/lesson/close.png" alt="close">
        </div>

        <div class="progress">
          <div class="progressBar" :style="{width: progressVal}"></div>
        </div>

        <div class="grammarTips" @click="grammarTipsClickHandle">
          <img src="@/assets/images/courses/book.png" alt="grammarTips">
        </div>

        <el-popover
          placement="bottom-end"
          width="240"
          trigger="click">
          <div class="settingsWrap">
            <div class="settingItem">
              <div class="settingName">{{$t('course.sound_speed')}}</div>
              <div class="playBackRateWrap">
                <div class="playBackRateVal">{{(playBackRate * 100).toFixed() + '%'}}</div>
                <el-slider
                  v-model="playBackRate"
                  :min="0.5"
                  :max="1.5"
                  :step="0.1"
                  :show-tooltip="false">
                </el-slider>
              </div>
            </div>
          </div>
          <div class="settings" slot="reference">
            <img src="@/assets/images/courses/setting.png" alt="settings">
          </div>
        </el-popover>
      </div>

      <div class="lessonMain">
        <div class="lessonWrap">
          <div class="tips">{{$t('main.complete_the_dialogue')}}</div>
          
          <template v-for="(dialogue, i) in dialogues">
            <component
              :is="dialogueModel(dialogue.kmo)"
              :key="i"
              ref="dialogueModel"
              :dialogueData="dialogue"
              :audioIndex="i"
              :audio="audio"
              @next="dialogueIndex ++"
              @clearPopoverAll="clearPopoverAll"
            />
          </template>
        </div>
      </div>

      <div class="finishPanel" v-if="finishPanel">
        <div class="replay panelBtn" @click="replayHandle">
          <div class="iconWrap">
            <img src="@/assets/images/courses/dialogue/replay.png" alt="replay">
          </div>
          <div class="btnName">{{$t('course.replay')}}</div>
        </div>
        <div class="redo panelBtn" @click="redoHandle">
          <div class="iconWrap">
            <img src="@/assets/images/courses/dialogue/redo.png" alt="redo">
          </div>
          <div class="btnName">{{$t('course.redo')}}</div>
        </div>
        <div class="done panelBtn" @click="doneHandle">
          <div class="iconWrap">
            <img src="@/assets/images/courses/dialogue/done.png" alt="done">
          </div>
          <div class="btnName">{{$t('course.FINISH')}}</div>
        </div>
      </div>

      <el-drawer
        title="grammarTips"
        :with-header="false"
        :visible.sync="grammarTipsDrawer"
        size="90%"
        direction="btt">
        <div class="grammarTipsDrawer" v-loading="grammarTipsLoading">
          <div class="grammarTipsWrap">
            <div class="content" v-html="grammarTips"></div>
          </div>
        </div>
      </el-drawer>
    </template>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getLessonData, getGrammarTipsData } from '@/api/courses'
  import constants from '@/utils/constants'
  import Audio from '@/utils/audioUtil'
  import dialogueM0 from '@/components/courses/models/dialogueM0.vue'
  import dialogueM1 from '@/components/courses/models/dialogueM1.vue'
  import dialogueM3 from '@/components/courses/models/dialogueM3.vue'
  import dialogueM5 from '@/components/courses/models/dialogueM5.vue'
  import dialogueM10 from '@/components/courses/models/dialogueM10.vue'
  import dialogueM13 from '@/components/courses/models/dialogueM13.vue'
  import dialogueM14 from '@/components/courses/models/dialogueM14.vue'
  import finish from '@/components/courses/DialogueFinish.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        id: '',
        dialogues: [],
        dialoguesData: [],
        dialogueIndex: 0,
        grammarTipsLoading: false,
        grammarTipsDrawer: false,
        grammarTips: '',
        audio: null,
        finishPanel: false,
        finish: false
      }
    },
    computed: {
      ...Vuex.mapGetters(['coursesDialogueInfo', 'userLanguage', 'learnLanguage', 'userSettings']),
      progressVal() {
        let progess = 0
        let total = this.dialoguesData.length
        if (total > 0) {
          let current = this.dialogueIndex + 1
          progess = ((current / total) * 100).toFixed(2) + '%'
        }
        return progess
      },
      playBackRate: {
        get() {
          return this.userSettings.playBackRate
        },
        set(val) {
          this.$store.commit('set_userSettings', { key: 'playBackRate', val })
        }
      }
    },
    components: {
      dialogueM0,
      dialogueM1,
      dialogueM3,
      dialogueM5,
      dialogueM10,
      dialogueM13,
      dialogueM14,
      finish
    },
    created() {
      this.initDialogue()
    },
    watch: {
      dialogueIndex(index) {
        this.$nextTick(function() {
          // 让页面始终在最底部
          let scrollArea = document.querySelector('.lessonMain')
          scrollArea.scrollTop = scrollArea.scrollHeight
        })

        if (this.dialoguesData.length) {
          if (index < this.dialoguesData.length) {
            this.init()
          } else {
            this.finishPanel = true
          }
        }
      },
      'audio.playState': function(val) {
        if (val === 'end' && this.dialogueIndex === this.audio.index) {
          setTimeout(() => {
            this.dialogueIndex ++
          }, 500)
        }
      }
    },
    methods: {
      init() {
        let currentDialogue = this.dialoguesData[this.dialogueIndex]
        this.dialogues.push(currentDialogue)
      },
      async initDialogue() {
        if (this.coursesDialogueInfo) {
          let loading = this.$loading()
          this.id = this.coursesDialogueInfo.id

          let dialogueData = await getLessonData(this.userLanguage, this.learnLanguage, this.id)
          this.dialoguesData = this.handleDialoguesData(dialogueData)
          this.loadAudios()

          this.$nextTick(function() {
            this.init()
            this.timingStart()
            loading.close()
          })
        } else {
          this.$router.push('/courses')
        }
      },
      loadAudios() {
        let dialoguesData = this.dialoguesData
        let audios = []
        for (let dialogue of dialoguesData) {
          let audioUrl =
            constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
            'lesson_f/' +
            constants.langsData[this.learnLanguage]['courseMediaName'] +
            `-f-s-${dialogue.id}.mp3`
          audios.push({ audioUrl })
        }
        this.audio = new Audio(audios)
      },
      handleDialoguesData(dialogueData) {
        let dialoguesData = []
        let { dialogRegex, list } = dialogueData
        let regexArr = dialogRegex.split('#')
        for (let regex of regexArr) {
          let regexStr = regex.split(';')[0]
          regexStr = '1' + regexStr.slice(1)
          let dialogueObj = list[regexStr]
          dialogueObj.type = this.getType(dialogueObj.wls[0].wd)
          dialogueObj.st = this.getSentence(dialogueObj.st).trim()
          dialogueObj.tr = this.getSentence(dialogueObj.tr).trim()
          dialoguesData.push(dialogueObj)
        }

        return dialoguesData
      },
      closeHandle() {
        this.$router.push('/courses')
      },
      async grammarTipsClickHandle() {
        this.grammarTipsDrawer = true

        if (!this.grammarTips) {
          this.grammarTipsLoading = true
          let unitID = this.coursesDialogueInfo.unitID
          let grammarTipsData = await getGrammarTipsData(this.userLanguage, this.learnLanguage, unitID)
          let grammarTips = grammarTipsData.replace(/\#ea9500/gi, '#004E94')
          grammarTips = grammarTips.replace("<link href='https://wap.lingodeer.com/javascript/jsLibrary/richText/css/froala_style.min.css' rel='stylesheet' type='text/css'/>", '')
          this.grammarTips = grammarTips
          this.grammarTipsLoading = false
        }
      },
      replayHandle() {
        this.finishPanel = false
        let dialogDoms = document.querySelectorAll('.dialogueModel')
        let replayIndex = 0
        let unwatch = this.$watch('audio.playState', function(val) {
          if (val === 'end') {
            if (replayIndex < this.dialogueIndex) {
              setTimeout(() => {
                dialogDoms[replayIndex].scrollIntoView({behavior: 'smooth'})
                this.audio.play(replayIndex)
                replayIndex ++
              }, 500)
            } else {
              this.finishPanel = true
              unwatch()
            }
          } 
        }, { immediate: true })
      },
      redoHandle() {
        Object.assign(this.$data, this.$options.data())
        this.initDialogue()
      },
      doneHandle() {
        this.finish = true
        this.timingStop()
      },
      dialogueModel(model) {
        model = model === '4' ? '5' : model
        return 'dialogueM' + model
      },
      getType(str) {
        if (['M:', 'H:', 'H : '].includes(str)) {
          return 'male'
        } else if (['F:', 'F : ', 'F1:', 'F&M:'].includes(str)) {
          return 'female'
        } else {
          return 'aside'
        }
      },
      getSentence(str) {
        constants.coursesDialoguePrefix.forEach(item => str = str.replace(item, ''))
        return str
      },
      clearPopoverAll() {
        for (let dialogueModel of this.$refs.dialogueModel) {
          dialogueModel.clearPopover()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .coursesDialogue {
    position: relative;
    background: #F3F4F8;
    .lessonHeader {
      margin: 0 auto;
      padding-top: 10px;
      height: 30px;
      width: 90vw;
      max-width: 960px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .close, .grammarTips, .settings {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
      }
      .close {
        img {
          width: 17px;
        }
      }
      .grammarTips, .settings {
        img {
          width: 22px;
        }
      }
      .progress {
        position: relative;
        margin: 0 10px;
        width: 100%;
        height: 12px;
        border-radius: 12px;
        background: #D8D8D8;
        overflow: hidden;
        &Bar {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100%;
          height: 100%;
          border-radius: 12px;
          background: #2BC58A;
          transition: width .5s;
        }
      }
    }
    .lessonMain {
      position: relative;
      width: 100%;
      height: calc(100vh - 30px);
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0;
      }
      .lessonWrap {
        margin: 0 auto;
        padding: 20px 20px 150px;
        max-width: 600px;
        .tips {
          padding: 20px 0;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: #2F2F4D;
        }
      }
    }
    .finishPanel {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 150px;
      background: #F3F4F8;
      border-top: 1px solid #E6E6E6;
      display: flex;
      justify-content: center;
      align-items: center;
      .panelBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &.redo {
          margin: 0 50px;
        }
        &:hover {
          .iconWrap {
            background: #0A4172;
          }
        }
        .iconWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          background: #004E94;
          box-shadow: 0 2px 4px 0 #004E944A;
          transition: all 0.3s;
          img {
            width: 100%;
          }
        }
        .btnName {
          margin-top: 5px;
          font-weight: 400;
          font-size: 14px;
          color: #585971;
        }
      }
    }
    .grammarTipsDrawer {
      width: 100%;
      height: 100%;
      .grammarTipsWrap {
        padding: 0;
        margin: 0 auto;
      }
    }
  }
</style>

<style lang="scss">
  .settingsWrap {
    padding: 8px;
    .settingItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;
      .settingName {
        font-weight: 400;
        font-size: 14px;
        color: #2F2F4D;
      }
      .playBackRateWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .playBackRateVal {
          margin-right: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #004E94;
        }
        .el-slider {
          width: 80px;
        }
        .el-slider__runway {
          background: #DCE4F2;
        }
        .el-slider__bar {
          background: #004E94;
        }
        .el-slider__button {
          width: 10px;
          height: 10px;
          background: #004E94;
          border: 1px solid #FFFFFF;
        }
      }
    }
  }
</style>