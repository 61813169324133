<template>
  <div class="dialogueModel" :class="dialogueData.type">
    <div class="stemWrap">
      <img class="personIcon" :src="require(`@/assets/images/${dialogueData.type}.png`)">
      <div class="sentWrap" @click="playAudioHandle">
        <div class="sent">
          <div class="audio lock" ref="dialogueAudio"></div>
          <template v-for="(word, i) in words">
            <el-popover
              popper-class="wordTransPopper"
              placement="bottom"
              trigger="manual"
              v-model="wordTransVisible[i]"
              :key="i">
              <div class="wordTrans">{{word.tr}}</div>
              <div v-if="word.ty === '1'" class="punc" :class="{highlight: word.highlight}" slot="reference">{{word.wd}}</div>
              <div v-else class="word" :class="{active: i === activeIndex, highlight: word.highlight}" @click.stop="showWordTransHandle(word, i, stems[i].wd !== underline)" slot="reference">
                <div :class="{blank: stems[i].wd === underline}">{{word.wd}}</div>
              </div>
            </el-popover>
          </template>
        </div>
        <div class="trans">{{dialogueData['tr']}}</div>
      </div>
    </div>

    <div class="optionsWrap" :class="{hidden: correct}">
      <hint @hint="hintHandle"></hint>
      <template v-for="(op, i) in options">
        <div class="option sentence" ref="op" @click="checkHandle(op, i)" :key="i">
          <div class="selectCircle"></div>
          <div class="optionContent">{{op.wd}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import dialogueMixins from '@/mixins/coursesDialogueMixins'

  export default {
    mixins: [ dialogueMixins ],
    data() {
      return {
        correct: false,
        answerID: '',
        options: [],
        stems: []
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        let { an, stems, ops } = this.dialogueData

        this.options = ops.sort(() => Math.random() - 0.5)
        this.stems = this.prefixFilter(stems)
        this.answerID = an
      },
      checkHandle(op, index) {
        let currentDom = this.$refs.op[index]
        if (!currentDom.classList.contains('wrong')) {
          if (op.id === this.answerID) {
            currentDom.classList.add('right')
            let stem = this.stems.find(item => item.wd === this.underline)
            stem.wd = op.wd
            setTimeout(() => {
              this.correct = true
              this.playAudioHandle()
            }, 500)
          } else {
            currentDom.classList.add('wrong')
            setTimeout(() => {
              currentDom.classList.remove('wrong')
              currentDom.classList.add('lock')
            }, 500)
          }
        }
      },
      hintHandle() {
        let index = this.options.findIndex((op) => {
          return op.id === this.answerID
        })
          
        let currentDom = this.$refs.op[index]
        currentDom.classList.add('right')
        setTimeout(() => {
          currentDom.classList.remove('right')
        }, 300)
      }
    }
  }
</script>