<template>
  <div class="dialogueModel D13" :class="dialogueData.type">
    <div class="stemWrap">
      <img class="personIcon" :src="require(`@/assets/images/${dialogueData.type}.png`)">
      <div class="sentWrap" @click="playAudioHandle">
        <div class="sent">
          <div class="audio lock" ref="dialogueAudio"></div>
          <template v-for="(word, i) in words">
            <div v-if="word.ty === '1'" class="punc" :class="{highlight: word.highlight}" :key="i">{{word.wd}}</div>
            <div v-else class="word" :class="{highlight: word.highlight}" :key="i">
              <template v-for="(char, j) in word.wd">
                <div class="char blank" ref="char" :key="j">{{char}}</div>
              </template>
            </div>

            <div class="word spacing" v-if="words[i+1] && words[i+1].ty !== '1'" :key="'spacing' + i">
              <div class="char blank" ref="char">&nbsp;</div>
            </div>
          </template>
        </div>
        <div class="trans">{{dialogueData['tr']}}</div>
      </div>
    </div>

    <div class="optionsWrap" :class="{hidden: correct}">
      <hint @hint="hintHandle"></hint>
      <template v-for="(op, i) in options">
        <div class="option word" ref="op" @click="checkHandle(op, i)" :key="i">{{op.char}}</div>
      </template>
    </div>
  </div>
</template>

<script>
  import dialogueMixins from '@/mixins/coursesDialogueMixins'

  export default {
    mixins: [ dialogueMixins ],
    data() {
      return {
        correct: false,
        answerStr: '',
        options: [],
        charIndex: 0
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        let words = this.words
        let answerStr = ''
        let optionsStr = ''

        for (let i = 0; i < words.length; i ++) {
          let word = words[i]
          if (word.ty !== '1') {
            answerStr += word.wd + ' '
            optionsStr += word.wd.split('').sort(() => Math.random() - 0.5).join('') + ' '
          }
        }

        this.answerStr = answerStr.trim()
        this.options = optionsStr.trim().split('').map(item => {
          return { char: item }
        })
      },
      checkHandle(op, index) {
        let currentDom = this.$refs.op[index]
        if (!currentDom.classList.contains('selected')) {
          if (op.char.toLowerCase() === this.answerStr.charAt(0).toLowerCase()) {
            currentDom.classList.add('right')
            currentDom.classList.add('selected')
            op.active = true
            setTimeout(() => {
              currentDom.classList.add('lock')
            }, 500)
            let currentChar = this.$refs.char[this.charIndex]
            currentChar.classList.remove('blank')
            this.charIndex ++
            this.answerStr = this.answerStr.slice(1)
            if (!this.answerStr.length) {
              setTimeout(() => {
                this.correct = true
                this.playAudioHandle()
              }, 500)
            }
          } else {
            currentDom.classList.add('wrong')
            setTimeout(() => {
              currentDom.classList.remove('wrong')
            }, 500)
          }
        }
      },
      hintHandle() {
        let currentChar = this.answerStr.charAt(0)
        for (let i = 0; i < this.options.length; i ++) {
          let option = this.options[i]
          let currentDom = this.$refs.op[i]
          if (!option.active && (option.char === currentChar)) {
            currentDom.classList.add('right')
            setTimeout(() => {
              currentDom.classList.remove('right')
            }, 300)
            return
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .D13 {
    .stemWrap {
      .sentWrap {
        .sent {
          .word {
            margin: 3px 1px;
          }
        }
      }
    }
  }
</style>